.card {
  position: relative;
  min-height: 400px;
  width: 700px;
  margin: 0 auto;
  margin-top: 5px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  box-sizing: border-box;
  padding: 20px;
}

.question-number {
  position: relative;
  width: 90px;
  margin: 0 auto;
  padding-left: 20px;
}

.card.flipped {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 20px 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.front {
  background: linear-gradient(#b0a8f9, #7d6eeb);
  color: #ffffff;
}

.back {
  transform: rotateY(180deg);
  background-color: #d4cfff;
  color: black;
  border: 2px solid #7d6eeb;
}

.question,
.answer {
  font-size: 1.25rem;
  margin: 0;
  align-items: left;
}

.back .answer {
  font-weight: 600;
}

.back div {
  font-size: 25px;
  position: absolute;
  top: 10px;
  left: 20px;
  color: green;
}

.flip-button {
  position: absolute;
  left: 300px;
  right: 300px;
  bottom: 20px;
  align-self: center;
  margin-top: 1rem;
  padding: 0.7rem 1rem;
  border-radius: 15px;
  border: none;
  background-color: #ffffff;
  color: #7d6eeb;
  font-weight: 600;
  box-shadow: 0px 0px 10px rgba(48, 48, 48, 0.5);
  cursor: pointer;
  transition: background-color 0.2s;
}

.flip-button:hover {
  background-color: #edebff;
}

.prev-button,
.next-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.2s;
}

.prev-button:hover,
.next-button:hover {
  background-color: #dddddd;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.button-container .prev-button {
  margin-right: 1rem;
}

.button-container .next-button {
  margin-left: 1rem;
}

.fa {
  margin-right: 5px;
  margin-left: 5px;
}

.prev-button {
  margin-right: 1rem;
  position: absolute;
  bottom: 10px;
  left: 15px;
}

.next-button {
  margin-left: 1rem;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.heading {
  margin-top: 80px;
  margin-bottom: 10px;
}

.category {
  font-family: Arial, sans-serif;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333333;
  text-shadow: 2px 2px #cccccc;
}

.description {
  font-family: Arial, sans-serif;
  font-size: 25px;
  font-weight: bold;

  text-transform: uppercase;
  color: #666666;
  margin-top: 5px;
}

/* Media queries for adaptive design */

/* Small devices (phones) */
@media only screen and (max-width: 600px) {
  .mb-12 {
    padding-top: 30px;
  }
  .card {
    height: 450px;
    width: 300px;
  }

  .front,
  .back {
    padding: 10px;
  }

  .question,
  .answer {
    font-size: 1.5rem;
  }

  .back div {
    position: static;
  }

  .flip-button,
  .prev-button,
  .next-button {
    font-size: 0.8rem;
  }

  .heading {
    margin-left: 5px;
  }

  .flip-button {
    left: auto;
    right: 120px;
  }
}
