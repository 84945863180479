.main{
    margin-top: 20vh;
    margin-bottom: 10vh;
}

@media only screen and (max-width: 768px) {
    .main{
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
}
@media only screen and (max-width: 480px) {
    .main{
        margin-top: 0;
        margin-bottom: 0;
    }
}