.cardContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 60px;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 65vh;
  width: 85vw;
  border-radius: 25px;
  padding: 50px;
  transform-origin: top;
  color: white;

  h2 {
    text-align: center;
    margin: 0;
    font-size: 26px;
    font-weight: 700;
  }

  .body {
    display: flex;
    height: 100%;
    margin-top: 20px;
    gap: 50px;

    .description {
      width: 40%;
      position: relative;
      top: 0;

      p {
        font-size: 16px;

        &::first-letter {
          font-size: 28px;
          font-family: 'Title';
        }
      }

      span {
        display: flex;
        align-items: center;
        gap: 5px;

        a {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .imageContainer {
      position: relative;
      width: 50%;
      height: 90%;
      

      .inner {
        width: 500px;
        height: 250px;
      }

      img {
        width: 100%; 
        height: auto;
        object-fit: cover;
      }
    }
  }
}

/* Adjustments for screens less than 768px wide */
@media only screen and (max-width: 768px) {
  .card {
    flex-direction: column;    
    height: 50vh;
    width: 90vw;
    padding: 20px;
    margin-top: 50px;
    gap: 2vh;
  }

  .body {
    flex-direction: column;
  }

  .description {
    width: 100% !important;
  }

  .imageContainer {
    display: none; /* Hide the image on smaller screens */
  }
}

/* Adjustments for screens less than 480px wide */
@media only screen and (max-width: 480px) {
  h2 {
    text-align: center;
    margin: 0;
    font-size: 18px !important;
    font-weight: 700;
  }
  .card {
    padding: 20px;
  }

  .body {
    gap: 10px;
  }

  .description p {
    font-size: 14px;
  }

  .description span a {
    font-size: 10px;
  }
}
