@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700;900&display=swap');
htm{
  scroll-behavior:smooth;
}
@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
.background-dot-image {
  background-image: url('./assets/dots.png');
  background-repeat: repeat;
  background-size:500px 500px;

}

/* @layer utilities {
  .underline-animation {
    @apply inline-block relative;
  }

  .underline-animation::after {
    content: '';
    @apply absolute bottom-0 left-0 bg-[#4ca8d6d7] h-[2px];
    width: 100%;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
  }

  .underline-animation:hover::after {
    animation: underline-appear 0.5s forwards, underline-disappear 0.5s 0.5s forwards;
  }

  @keyframes underline-appear {
    0% {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
    100% {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  @keyframes underline-disappear {
    0% {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    100% {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
  }
} */
.person-card {
  position: relative;
  overflow: hidden;
  color: white;
  background-size: cover; 
  background-repeat: no-repeat;
}

.person-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px);
  z-index: -1; 
}


 @layer utilities {
  .underline-animation {
    @apply inline-block relative ;
  }

  .underline-animation::after {
    content: '';
    @apply absolute bottom-0 left-0 bg-[#7F56D9] h-[2px];
    width: 100%;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    
  }

  .underline-animation:hover::after {
    animation: underline-appear 0.5s forwards, underline-disappear 0.5s 0.5s forwards;
  }
  .carousel-arrows {
    @apply size-14 font-thin text-[#667085] bg-white rounded-full p-4 cursor-pointer shadow-md;
  }

  @keyframes underline-appear {
    0% {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
    100% {
      transform: scaleX(1);
      transform-origin: bottom right;
    }
  }

  @keyframes underline-disappear {
    0% {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    100% {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
  }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
  .gradient-text{
    font-size: 40px; 
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif; 
    /* background: linear-gradient(to right, #f32170, 
            #ff6b08, #cf23cf, #eedd44);  */
    background: linear-gradient(to right, #663399, 
            #9932cc, #7B68EE); 
    -webkit-text-fill-color: transparent; 
    background-clip: padding-box;
    -webkit-background-clip: text; 
  }
}
html::-webkit-scrollbar {
  display: none;
}
html{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* firefox */
}

img{
  pointer-events: none;
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}