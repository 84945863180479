.modal {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  position: fixed;
  right: 30px;
  top: 70px;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #888;
}
