.parentDiv {
  position: relative;
}
.mainframe {
  margin-top: 80px;
  background-color: whitesmoke;
  z-index: -1;
  position: relative;
}
.backframe {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainframe .heading {
  background-color: #d4cfff;
  color: #000;
  text-align: center;
  text-shadow: 2px 4px 4px rgba(125, 110, 235, 0.3);
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.program {
  color: #7d6eeb;
  text-align: center;
  text-shadow: 2px 4px 4px rgba(125, 110, 235, 0.3);
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 2.5rem;
}
.plans {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.card {
  border-radius: 26px;
  background: linear-gradient(180deg, #d7d2fc -18.7%, #7d6eeb 72.79%);
  box-shadow: 10px 10px 20px 10px rgba(125, 110, 235, 0.1);
}
.card2 {
  border-radius: 26px;
  background: #fff;
  box-shadow: 0px 0px 34px 0px rgba(125, 110, 235, 0.2);
}
.cardHeading {
  color: #fff;
  text-align: center;
  padding: 2rem 2rem 1rem 2rem;
}

.cardHeading h1 {
  font-size: x-large;
  font-weight: bolder;
}
.cardHeading p {
  font-size: medium;
  font-weight: bolder;
}
.line {
  height: 1rem;
  margin-left: 10%;
}
.benefits {
  padding: 5% 0% 50% 10%;
  color: #ffffff;
}
.card .getStartBtn {
  background-color: #ffffff;
  color: #7d6eeb;
  border: none;
  border-radius: 12px;
  padding: 5% 10%;
}
.plan {
  text-align: center;
  color: #ffffff;
}
