.navbar {
  background-color: white;
  
  transition: background-color 0.3s ease;
}

.navbar.scrolled {
  box-shadow:0.5px 0.5px 10px rgb(82, 80, 80);
}
.navbar_items {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
